import {
	AppTheme,
	BudgetsSettings,
	DashboardsSettings,
	LeadsMonitorSettings,
	Settings,
	TableSettings,
	TeamsManageSettings,
	UsersManageSettings,
} from '@emrm/settings/types';
import { TableRowsDensity } from '@emrm/ui-kit';

export const FEATURE_KEY = 'settings';

export interface SettingsState {
	loading: boolean;
	error: string | null;
	settings: Settings;
}

export const getInitialSettings = () => {
	return {
		global: getInitialGlobalSettings(),
		chatGpt: getInitialChatGptSettings(),
		leadsMonitor: getInitialLeadsMonitorSettings(),
		usersManage: getInitialUsersManageSettings(),
		teamsManage: getInitialTeamsManageSettings(),
		budgets: getInitialBudgetsSettings(),
		dashboards: getInitialDashboardsSettings(),
	} as Settings;
};

export const getInitialGlobalSettings = () => {
	return {
		theme: AppTheme.Light,
	};
};

export const getInitialChatGptSettings = () => {
	return {
		width: 400,
		height: 590,
	};
};

export const getInitialLeadsMonitorSettings = () => {
	return {
		notificationSound: true,
		leadsType: '',
		tableSettings: getInitialTableSettings(),
	} as LeadsMonitorSettings;
};

export const getInitialUsersManageSettings = () => {
	return {
		tableSettings: getInitialTableSettings(),
	} as UsersManageSettings;
};

export const getInitialTeamsManageSettings = () => {
	return {
		tableSettings: getInitialTableSettings(),
	} as TeamsManageSettings;
};

export const getInitialBudgetsSettings = () => {
	return {
		tableSettings: getInitialTableSettings(),
	} as BudgetsSettings;
};

export const getInitialDashboardsSettings = () => {
	return {
		tablesSettings: {},
	} as DashboardsSettings;
};

export const getInitialTableSettings = () => {
	return {
		rowsDensity: TableRowsDensity.sm,
		showVerticalDividers: true,
		showHorizontalDividers: true,
	} as TableSettings;
};
