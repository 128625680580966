import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import _ from 'lodash';

import {
	Settings,
	AppTheme,
	LeadsMonitorSettings,
	UsersManageSettings,
	TeamsManageSettings,
	BudgetsSettings,
	TableSettings,
} from '@emrm/settings/types';
import {
	loadSettings,
	saveSettings,
	selectSettings,
} from '@emrm/settings/store';
import { AuthService } from '@emrm/core/auth/service';

@Injectable()
export class SettingsService {
	settings$ = new BehaviorSubject<Settings | null>(null);

	private userId: number | null = null;
	private settings: Settings | null = null;
	private themeChangeSubscription!: Subscription;

	constructor(
		private readonly store: Store,
		private readonly authService: AuthService,
	) {}

	initSettings() {
		this.userId = this.authService.getRiesId();

		if (this.userId) {
			this.store.dispatch(loadSettings({ id: this.userId }));
		}

		this.store.select(selectSettings()).subscribe((settings) => {
			this.settings$.next(settings);
			this.settings = settings;
		});
	}

	// === APP THEME ===

	setTheme(theme: AppTheme) {
		if (theme === AppTheme.System) {
			this.subscribeToSystemThemeChange();
		}

		document.documentElement.setAttribute('theme', theme);
	}

	saveTheme(theme: AppTheme) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.global.theme = theme;

			this.saveSettings(settings);
		}
	}

	private subscribeToSystemThemeChange() {
		if (this.themeChangeSubscription) {
			this.themeChangeSubscription.unsubscribe();
		}

		const systemThemeChange$ = fromEvent<MediaQueryListEvent>(
			window.matchMedia('(prefers-color-scheme: dark)'),
			'change',
		);

		this.themeChangeSubscription = systemThemeChange$.subscribe((event) => {
			const isDarkMode = event.matches;

			document.documentElement.setAttribute(
				'theme',
				isDarkMode ? AppTheme.Dark : AppTheme.Light,
			);
		});
	}

	// === END of APP THEME ===

	// === CHAT GPT ===

	setChatGptWindowSize(width: number, height: number) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.chatGpt = {
				...settings.chatGpt,
				width,
				height,
			};

			this.saveSettings(settings);
		}
	}

	// === END of CHAT GPT ===

	// === LEADS MONITOR ===

	setLeadsMonitorSettings(lmSettings: LeadsMonitorSettings) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.leadsMonitor = {
				...settings.leadsMonitor,
				...lmSettings,
			};

			this.saveSettings(settings);
		}
	}

	// === END of LEADS MONITOR ===

	// === USERS MANAGE ===

	setUsersManageSettings(umSettings: UsersManageSettings) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.usersManage = {
				...settings.usersManage,
				...umSettings,
			};

			this.saveSettings(settings);
		}
	}

	// === END of USERS MANAGE ===

	// === TEAMS MANAGE ===

	setTeamsManageSettings(tmSettings: TeamsManageSettings) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.teamsManage = {
				...settings.usersManage,
				...tmSettings,
			};

			this.saveSettings(settings);
		}
	}

	// === END of TEAMS MANAGE ===

	// === BUDGETS  ===

	setBudgetsSettings(bSettings: BudgetsSettings) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.budgets = {
				...settings.budgets,
				...bSettings,
			};

			this.saveSettings(settings);
		}
	}

	// === END of BUDGETS  ===

	// === DASHBOARDS  ===

	setDashboardTableSettings(dashboard: string, tableSettings: TableSettings) {
		if (this.settings) {
			const settings = _.cloneDeep(this.settings);

			settings.dashboards.tablesSettings = {
				...settings.dashboards.tablesSettings,
				[dashboard]: tableSettings,
			};

			this.saveSettings(settings);
		}
	}

	// === END of DASHBOARDS  ===

	// === SETTINGS CORE ===

	private saveSettings(settings: Settings) {
		this.settings = settings;

		if (this.userId) {
			this.store.dispatch(
				saveSettings({ id: this.userId, settings: this.settings }),
			);
		}
	}

	// === END of SETTINGS CORE ===
}
