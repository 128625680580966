import { Settings } from '@emrm/settings/types';
import { createAction, props } from '@ngrx/store';

const ACTIONS_CODE = 'Settings';
const ACTIONS_API_CODE = `[${ACTIONS_CODE} API]`;

// LOAD

export const loadSettings = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load`,
	props<{ id: number }>(),
);

export const loadSettingsSuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Success`,
	props<{ settings: Settings }>(),
);

export const loadSettingsFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Load Failure`,
	props<{ error: string }>(),
);

// SAVE

export const saveSettings = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Save`,
	props<{ id: number; settings: Settings }>(),
);

export const saveSettingsSuccess = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Save Success`,
	props<{ settings: Settings }>(),
);

export const saveSettingsFailure = createAction(
	`${ACTIONS_API_CODE} ${ACTIONS_CODE} Save Failure`,
	props<{ error: string }>(),
);
