import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FEATURE_KEY, SettingsState } from './state';

export const featureSelector =
	createFeatureSelector<SettingsState>(FEATURE_KEY);

export const selectSettingsLoading = () =>
	createSelector(featureSelector, (state) => state.loading);

export const selectSettingsError = () =>
	createSelector(featureSelector, (state) => state.error);

export const selectSettings = () =>
	createSelector(featureSelector, (state) => state.settings);
